.home {
    margin: auto;
    overflow: hidden;
}

.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  justify-content: center;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .below {
  z-index: 1;
}
.outer .top {
  z-index: 2;
}

.noclick {
  pointer-events: none;
}

.container {
    display: table;
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    text-align: center;
}

.table-container {
    width: 93%;
    height: 100vh; 
    margin: 0 auto;
    margin-left: auto;
    place-items: center;
    align-items: center;
    justify-content: center;

}

.table-cell {
    width: 100%;
    margin-right: 100px;
}

.table-cell h1 {
    font-size: 1.75rem;
    font-weight: 400;
    color: black;
    text-align: right;
    margin-top: 0;
    margin-bottom: 0;
}

.table-cell h2 {
  font-size: 1.40rem;
  font-weight: 600;
  color: grey;
  text-align: right;
}

.table-cell p {
  font-size: 20px;
  color: black;
  text-align: right;
  margin-top: 10%;
}

.table-cell ul {
    font-size: 22px;
    color: grey;
    text-align: right;
    list-style-position: inside;
    list-style: none;
    font-weight: 500;
}

canvas {
    width: 100%;
    height: 100%;
}

@keyframes colorChange {
    0% {
      color: initial; /* Start color */
    }
    50% {
      color: red; /* Temporary color */
    }
    100% {
      color: initial; /* End color */
    }
  }
  
  .demonictransition:hover {
    animation: colorChange 1s linear; /* Adjust duration as needed */
  }

.overlay-text {
    position: absolute;
    top: 100px; /* Adjust as needed */
    left: 300px; /* Adjust as needed */
    color: black; /* Adjust text color as needed */
    font-size: 10px; /* Adjust font size as needed */
    pointer-events: none; /* Ensure the text doesn't interfere with canvas interactions */
    font-weight: bold;
}


.dynamic-margin {
  padding-top: 15px;
  margin: 0px;
  margin-bottom: 5px;
}

.dynamic-margin-head {
  padding-top: 15px;
  margin: 0px;
  margin-bottom: 0px;
}

ul > li:last-child {
  margin-bottom: .5rem;
}

ul {
	margin-top: 0px;
  margin-bottom: 0px;
}

@media (max-height: 600px) {
  .dynamic-margin-head {
      margin-top: 0px;
  }
  
}

/* styles.css */
@media (max-width: 600px) {
  .hide-on-small {
      display: none;
  }
}
