.custom-h2 {
  display: block;
  font-size: 1.5em;
  color: grey;
  font-weight: bold;
  unicode-bidi: isolate;
}

.navbar-container {
  top: 0;
  z-index: 1000;
  position: fixed;
  justify-content: center;
  pointer-events: all;
  width: 100%;
  }

.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  padding: 15px 15px 15px 15px;
}

.navbar a {
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 200;
}
    
.navbar-links {
  display: flex;
  justify-content: center;
}

.navbar .dot {
  margin: 0;
}

iframe[style*="z-index: 2147483647"] {
  z-index: 1 !important; /* Lower the z-index */
  /* Or hide the iframe */
  display: none !important;
}
