.work canvas {
    position: absolute; /* Ensure the canvas is positioned absolutely */
    top: 0px; /* Move the canvas up by 5px */
    left: 0px; /* Move the canvas left by 5px */
    z-index: 0;
    width: 100%; /* Increase the width by 10px */
    height: 100%; /* Increase the height by 10px */
    display: block;
}

html, body {
    margin: 0; /* Remove default margin */
    height: 100%; /* Ensure the body takes the full height of the viewport */
}
