.about {
    margin: 0;
    overflow-y: hidden;
    overflow-x: hidden;
}
  
.spacer {
    width: 100%;
    height: 100vh;
    position: relative;
  }
.apanel {
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  place-content: center;
  align-items: center;
  display: flex;
  will-change: transform;
  overflow-y: hidden;
  overflow-x: hidden;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.center {
  position: absolute;
  place-content: center;
  font-size: 30px; 
  pointer-events: none; 
  font-weight: 500;
  z-index: 4;
  transform: translateY(-210%);
}

.under-center {
  position: absolute;
  font-size: 1.5rem; 
  pointer-events: none; 
  font-weight: 500;
}

.under-center h2 {
  font-weight: 500;
}


@keyframes idleY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.overlay-text-down {
  position: absolute;
  top: 90%; /* Adjust as needed */
  left: 90%; /* Adjust as needed */
  color: black; /* Adjust text color as needed */
  font-size: 25px; /* Adjust font size as needed */
  pointer-events: none; /* Ensure the text doesn't interfere with canvas interactions */
  font-weight: 400;
  animation: idleY 1.5s infinite;
}

.middle-top-img {
  position: absolute;
  top: 7%; /* Adjust as needed */
  left: 33%; /* Adjust as needed */
  height: 15%;
  width: 35%;
  object-fit: fill;

}

.middle-left-rotated-img {
  position: absolute;
  top: 30%; /* Adjust as needed */
  left: 15%; /* Adjust as needed */
  height: 30%;
  width: 15%;
  object-fit: fill;
  transform: rotate(-30deg);
}

.middle-right-rotated-img {
  position: absolute;
  top: 30%; /* Adjust as needed */
  left: 70%; /* Adjust as needed */
  height: 35%;
  width: 18%;
  object-fit: fill;
}

.middle-bottom-img {
  position: absolute;
  top: 65%; /* Adjust as needed */
  left: 37.5%; /* Adjust as needed */
  height: 30%;
  width: 25%;
  object-fit: fill;
}

/* Media query for mobile viewports */
@media (max-width: 768px) {
  .middle-top-img,
  .middle-left-rotated-img,
  .middle-right-rotated-img {
    display: none;
  }
  .middle-bottom-img {
    top: 55%;
    width: 50%;
    place-content: center;
    /* move it down left 50% */
    transform: translate(-25%, 50%);
  }
  .center {
    transform: translateY(-350%);
  }
  .under-center {
    font-size: 20px;
    transform: translateY(-40%);
  }
  .overlay-text-down {
    top: 93%;
    left: 78%;
    font-size: 20px;
    white-space: nowrap;
  }
}
