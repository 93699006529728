.project h4 {
    font-weight: 400;
}

.project .card {
   border-width: 1px;
}

.card {
    box-sizing: border-box;
    transition: transform 0.2s;
    height: 100%;
}

.card span {
    margin-right: 5px;
}

.card:hover {
    transform: scale(1.01);
    box-shadow: 5px 4px 10px -2px #e9ecef;
}

[class^="col-"] {
    margin-bottom: 1em;
}